<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    ><span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        {{ $t('general.hi') }},
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ user.name.split(' ')[0] }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="picture" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ user && user.name ? user.name[0].toUpperCase() : 'A' }}
        </span>
      </span>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">{{ $t('general.user_profile') }}</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="user.avatar" alt="" />
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ user.name }}
            </a>
            <div class="text-muted mt-1"> {{user.title}}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">

                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />

                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ user.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{$t('general.logout')}}
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link :to="{name: 'profile.edit'}"  @click.native="closeOffcanvas" href="" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
               <div class="font-weight-bold">{{$t('profile.user_profile')}}</div>
                <div class="text-muted">
                 {{$t('profile.user_settings_and_more')}}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
<script>
import {
  LOGOUT,
    USER
} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    myProfile(){
      this.$router.push({name:"profile.edit"})
    },
  },
  computed: {
    user () {
     return JSON.parse(localStorage.getItem('user'))
    }
  }
};
</script>
