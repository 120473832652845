var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menu && _vm.activeMenu && _vm.menu.hasOwnProperty(_vm.activeMenu))?_c('ul',{staticClass:"menu-nav",staticStyle:{"height":"100%","overflow-y":"auto"}},[_vm._l((_vm.menu[_vm.activeMenu].children),function(item,index){return [(_vm.activeMenu && item.children)?_c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(item.link) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(item.children)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(item.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2 mt-2'}))}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(item.text)))]),(_vm.menuState[item.id])?_c('span',{staticClass:"menu-label",class:item.id},[_c('span',{staticClass:"label label-rounded label-danger"},[_vm._v(_vm._s(_vm.menuState[item.id]))])]):_vm._e(),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu",staticStyle:{"display":"none","overflow":"hidden"}},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(item.text)))])])]),_vm._l((item.children),function(subMenu,subMenuIndex){return (!_vm.hasNestedChildren(subMenu))?_c('router-link',{key:subMenuIndex,attrs:{"to":subMenu.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(subMenu.icon, { class: 'w-25px h-25px object-cover d-inline-block mr-2 mt-2'}))}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(subMenu.text)))]),(_vm.menuState[subMenu.id])?_c('span',{staticClass:"menu-label",class:subMenu.id},[_c('span',{staticClass:"label label-rounded label-danger"},[_vm._v(_vm._s(_vm.menuState[subMenu.id]))])]):_vm._e()])])]}}],null,true)}):_vm._e()}),_vm._l((item.children),function(subMenu,subMenuIndex){return (_vm.hasActiveChildren(subMenu))?_c('li',{key:subMenuIndex,staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(item.menu.link) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(subMenu.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'}))}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(subMenu.text)))]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(subMenu.text)))]),(_vm.menuState[subMenu.id])?_c('span',{staticClass:"menu-label",class:subMenu.id},[_c('span',{staticClass:"label label-rounded label-danger"},[_vm._v(_vm._s(_vm.menuState[subMenu.id]))])]):_vm._e()]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(0,true),_vm._l((subMenu.children),function(nestedMenu,nestedMenuIndex){return _c('router-link',{key:nestedMenuIndex,attrs:{"to":nestedMenu.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(nestedMenu.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'}))}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(nestedMenu.text)))]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(nestedMenu.text)))]),(_vm.menuState[nestedMenu.id])?_c('span',{staticClass:"menu-label",class:nestedMenu.id},[_c('span',{staticClass:"label label-rounded label-danger"},[_vm._v(_vm._s(_vm.menuState[nestedMenu.id]))])]):_vm._e()])])]}}],null,true)})})],2)])]):_vm._e()})],2)])]):_vm._e(),(_vm.activeMenu && !item.children)?_c('router-link',{attrs:{"to":item.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(item.icon, { class: 'w-30px h-30px object-cover d-inline-block mr-2'}))}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(item.text)))]),(_vm.menuState[item.id])?_c('span',{staticClass:"menu-label",class:item.id},[_c('span',{staticClass:"label label-rounded label-danger"},[_vm._v(_vm._s(_vm.menuState[item.id]))])]):_vm._e()])])]}}],null,true)}):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
}]

export { render, staticRenderFns }