<template>
  <ul class="menu-nav" v-if="menu">
    <router-link v-for="(item, index) in menu" :key="index" :to="item.menu.link"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]">
        <a :href="href" class="menu-link" @click="navigate">
          <span v-html='getIconByKey(getActiveMenuIcon(isActive, item), { class: "w-30px h-30px object-cover d-inline-block mr-2"})'></span>
          <span class="menu-text">
            {{ $t(item.menu.text) }}
            <span class="menu-label" :class="item.menu.id" v-if="menuState[item.menu.id]">
            <span class="label label-rounded label-danger">{{ menuState[item.menu.id] }}</span>
          </span>
          </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapState} from "vuex";
import {BUNDLE} from "@/core/services/store/menu.module";

export default {
  name: "KTMenu",
  computed: {
    ...mapState({
      menuState: state => state.menu[BUNDLE]
    }),
    menu() {
      return this.authUser ? this.authUser.menu : null;
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasNestedChildren(menu) {
      return menu.hasOwnProperty('children');
    },
    getActiveMenuIcon(isActive, item) {
      return isActive ? item.menu.icon+`_1` : item.menu.icon
    }
  }
}
</script>
