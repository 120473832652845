<template>
  <ul class="menu-nav w-100 absolute" style="bottom: 0;">
    <router-link :to="{name: 'time-sheet.index'}" v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="isUserGranted('TimeSheet', ['view', 'viewAny'], false)">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item "
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span v-html="getIconByKey('icons.menu.time_sheet', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
          <span class="menu-text">{{ $t('menu.time_sheet') }}</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name: 'setting.notification'}" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item "
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span v-html="getIconByKey('icons.menu.notifications', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
          <span class="menu-text">{{ $t('menu.notifications') }}</span>
          <!-- 
            <span class="menu-label system-notification" v-if="Number(menuState['system-notification']) > 0" >
            <span class="label label-rounded label-danger">{{ menuState['system-notification'] }}</span>
          </span> 
        -->
        </a>
      </li>
    </router-link>

    <router-link :to="{name: 'setting.notification'}"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item "
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span v-html="getIconByKey('icons.menu.messages', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
          <span class="menu-text">{{ $t('menu.messages') }}</span>
        </a>
      </li>
    </router-link>

    <router-link :to="{name: 'profile.edit'}"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item "
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span v-html="getIconByKey('icons.menu.profile_and_setting', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
          <span class="menu-text">{{ $t('menu.profile_and_setting') }}</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item py-0 h-35px name-title"
    >
      <a class="menu-link d-flex justify-content-center hover-none flex-column py-1 cursor-auto">
        <span class="menu-text">
          <span class="bg-gray-200 text-center py-1 border-15px text-md text-navy-blue w-100 d-inline-block menu-text">
                      {{ authUser && authUser.name || 'user_name' }}
          </span>
        </span>
      </a>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item py-0 h-35px"
    >
      <a class="menu-link d-flex justify-content-center hover-none flex-column py-1 cursor-auto">
        <span class="menu-text">
          <span class="bg-gray-200 text-center py-1 border-15px text-md text-navy-blue w-100 d-inline-block menu-text">
                      {{ authUser && authUser.title || 'title' }}
          </span>
        </span>
      </a>
    </li>

    <li
        aria-haspopup="true"
        class="menu-item px-2 mt-3"
    >
      <div class="row mx-0 px-0 menu-link hover-none d-flex justify-content-center">
        <div class="col-4 mx-0 d-flex justify-content-center">
          <span class="menu-text" v-html="getIconByKey('self.logo.round_logo', { class: 'w-100 h-40px object-contain d-inline-block mr-2'})"></span>
        </div>
        <div class="col-4 mx-0 px-0 d-flex justify-content-center items-center menu-link">
          <router-link :to="{name: 'setting.notification'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">

            <a :href="href" class="menu-text" @click="navigate">
              <div class="d-flex flex-column">
                <span class="d-flex justify-content-center" v-html="getIconByKey('icons.menu.live_help', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
                <span class="d-block text-navy-blue">{{ $t('menu.live_help')}}</span>
              </div>
            </a>
          </router-link>
        </div>
        <a class="col-4 mx-0 px-0 d-flex justify-content-center padding-on-min">
          <div class="d-flex flex-column" @click="onLogout">
              <span class="d-flex justify-content-center" v-html="getIconByKey('icons.menu.logout', { class: 'w-30px h-30px object-cover d-inline-block mr-2'})"></span>
              <span class="d-block text-navy-blue">{{ $t('menu.logout')}}</span>
          </div>
        </a>
        <div class="col-12 d-flex justify-content-center copyright">
          <b-row class="d-flex justify-content-center text-center">
            <b-col md="12" class="pr-1">
              <span>2020 ©</span>
              <a href="http://www.limonist.com" target="_blank">Limonist</a>
            </b-col>
            <b-col md="12">
              <a href="http://www.limonist.com" target="_blank" class="">
                <img src="https://www.limonist.com/img/theme-1/logo-act.png" alt="" height="25" class="text-center">
              </a>
            </b-col>
          </b-row>
        </div>

      </div>
    </li>

  </ul>
</template>

<script>
import {LOGOUT} from "@/core/services/store/auth.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_ITEMS as REST_GET_ITEM} from "@/core/services/store/REST.module";
import {mapGetters, mapState} from "vuex";
import {BUNDLE} from "@/core/services/store/menu.module";

export default {
  name: "BottomAside",
  data() {
    return {
      notifications:[],
      notificationCount:null,
    };
  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapState({
      menuState: state => state.menu[BUNDLE]
    }),
  },

}
</script>

<style scoped>
 .hover-none:hover{
   background-color: transparent !important;
   color: #ddd !important;
 }
 .aside-minimize .name-title {
   display: none;
 }
 .name-title {
   display: table-cell;
 }
 .aside-minimize .padding-on-min {
   padding-left: 15px !important;
 }
 .copyright{
   margin-top: 7px;
   padding-top: 7px;
   border-top: 1px solid #cfcfcf;
 }
 .copyright a{
   padding-left: 5px;
 }
</style>
